.storecard{
    background-color: rgba(0, 0, 0, 0.041);
    border-radius:5px ;
  /*  border: 0px solid orange;  */
   height: 22vh;
   margin-bottom:10px !important;
      /*box-shadow:0px 0px 15px -10px rgb(0,0,0);
    -webkit-box-shadow:0px 0px 15px -10px rgb(0,0,0);*/
}
.store-card-title{
    color:black;
    height:60px;
    background-color:transparent;
   /*  border-bottom:1px solid snow */
}
.storeImg {
    width: 100%;
    border-radius: 50%;
    background-color:#2196f3;
    text-align: center;
    position: relative;
    aspect-ratio: 1/1;
  }
