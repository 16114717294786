.client-transactions{
    flex: 4;
    padding: 0px;
    overflow: scroll;
    margin-bottom: 50px;
    width: 100%;
    height: 100vh;
    border:0px solid;
    margin-right: 10px;
    background-color: white;
}
.pageTitleContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 5px;
    
 }
    
    .pageTitle{
        border: none;
        padding: 5px 10px;
        height: 34px;
        border-radius: 5px;
        font-weight: 600;
    }
    .pageTitleButton{
        border: none;
        padding: 5px 10px;
        background-color: --dashboard-primary;
        color: white;
        cursor: pointer;
        border-radius: 5px;
        font-size: 16px;
    }
    .select-store{
        padding: 5px 7px;
        margin-right: 10px;
        border-color: --dashboard-primary;
        border-radius: 5px;
    }
.transStatusSpan{
    padding: 5px 7px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
.transStatusSpan.Approved{
    background-color: #e5faf2;
    color:#3bb077 ;
  }
  .transStatusSpan.Declined{
    background-color: #fff0f1;
    color:#d95087 ;
       

  }
  .transStatusSpan.Pending{
    background-color: #ebf1fe;
    color:#2a7ade ; 
    border:1px solid #2a7ade ;
  }

  .actionButtonsContainer{
      padding: 5px 7px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 300px;
      border:1px solid;
  }
  .actionButtonsWrapper{
    display:flex;
    justify-content:space-between;
    width:50%;
    margin:10px
  }
  .actionButtons{
    border: 0px;
    background-color: #ebf1fe;
    color:#2a7ade ;
    border-radius: 4px;
  }
  .showall{
    padding: 3px 5px ;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #2a7ade;

  }
  .circularProgress{
    position:fixed;
    margin: 25% 40%;
  }
  @media only  screen and (max-width:620px){
    .transactions{
        padding: 0px;
        margin: 0px;

    }
    .transStatusSpan{
      padding: 4px 10px;
      border-radius: 4px;
    }
    .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-root .MuiDataGrid-cell {
      padding: 1px !important;
      
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
     
       padding: 0px !important;
      

  }
  .MuiDataGrid-menuIcon {
    display: none !important;
}
.MuiDataGrid-footerContainer{
  display: none !important;
}
    .actionButtonsWrapper {
      display: block;
      width: 96%;
      
  }
  .pageTitle{
    font-size: 16px;
  }
}