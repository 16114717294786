.newstore{
   flex: 4;
   padding: 20px;
   height: 100vh;
   overflow: scroll;
}
.newStoreForm{
border: 0px solid;
}
.client-newStoreFormContainer{
 padding: 20px 40px;
 border-radius: 0px;
/* box-shadow:0px 0px 15px -10px rgb(0,0,0.75);
    -webkit-box-shadow:0px 0px 15px -10px rgb(0,0,0.75 );*/
}
.addnewStoreTitleContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 5px;
}
.addNewStoreTitle{
    font-weight: 600;
    margin-bottom: 5px;
}
.newStoreFormLeft{
    padding:0px;
    border: 0px solid;
    width: 38%;
    
}
.newStoreFormRight{
    padding:40px 40px;   
}
.newStoreFormItem-column{
    /* */  width:100%;
     display: flex;
     flex-direction: column;
     font-weight:600px;
/*      color: rgb(151, 150, 150);
 */     border:0px;
     margin-top: 3px;
 }
.newStoreFormItem{
   /* */  width:100%;
    display: flex;
    flex-direction: row;
    font-weight:600px;
   /*  color: rgb(151, 150, 150); */
    border:0px;
    margin-top: 3px;
    margin-right: 3px;
}
.checkList{
    border:1px solid rgb(151, 150, 150);
    height:220px ;
    overflow: scroll;
    margin-top: 5px;
    margin-bottom: 15px;
    
}
.newStoreFormItem>label{
    margin: 8px;
    font-size: 11;
    width:38%;
    text-align: right;
}
.newStoreFormItem>input{
    height: 30px;
    width: 100%;
    border: 1px;
    border-bottom:1px solid rgb(151, 150, 150);
}
.newStoreFormItem>textarea{
    padding: 20px 10px;
    border:1px solid rgb(151, 150, 150);

}
.newStoreFormItem>select{
    padding: 5px;
    border:1px solid rgb(151, 150, 150);

}

.newStoreFormRight{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.storeListButton{
    border: none;
    padding:5px 10px ;
    background-color: var(--dashboard-primary);
    color: white;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
}
.newStoreUpload{
    margin-top: 5px;
    margin-bottom: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    
}
.newStoreUploadIcon{
    cursor: pointer;
}
.newStoreUploadButton{
    border-radius: 5px;
    border: none;
    padding:5px;
    cursor: pointer;
    background-color: darkblue;
    color: white;
    font-weight: 600;
    width: 100%;
    height: 30px;
}
.newStoreUploadImg{
    width: 120px;
    height: 120px;
    margin-right: 10px;
    border: 1px solid var(--app-primary);
}
@media only  screen and (max-width:620px){
    .newstore{
        padding: 0px;
        background-color: white;
    }
    .catIcon{
    width:20px;
    height:20px
    }
    .client-page-content{
      padding:6px
    }
    .addnewStoreTitleContainer{
        padding: 5px 5px;
    }
    .client-newStoreFormContainer{
 
        padding: 5px 10px;
       
       }
}