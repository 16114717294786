.MuiDialog-paper{
    width: 100%;
    background-color: var(--app-primary);
    position: relative;
}
.client-send-Button{
    text-transform: none !important;
    display: 'inline-block';
    padding: 5px 15px !important;
    min-height: 0;
    min-width: 0;
    font-size: 1rem !important;
  
  }

   