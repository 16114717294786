
.settings{
    flex: 4;
    padding:5% 5%;
    margin-bottom: 50px;
    height:100vh;
    overflow: scroll;
}
.settingsTitleContainer{
    border:1px solid var(--border-color);

    display: flex;
    align-items: center;
    justify-content:space-between;

}
.settingsContainer{
    border:1px solid var(--border-color);

}
.tabs{
    margin:3px 5px;
    border:1px solid var(--border-color);

}
