.mystore{
   background-color:white;
    flex:4;
    padding: 20px; 
     height:100vh;
    overflow: scroll;
}
.storeTitleContainer{
   display: flex ;
   align-items: center;
   justify-content: space-between;
   padding: 10px 5px;
}
.storeAddButton{ 
    border: none;
    padding:5px 10px ;
    background-color: var(--dashboard-primary);
    color: white;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
}
.storeContainer{
    display: flex;
    margin-top: 20px;
    
}
.client-storeShow{
    flex: 1;
    padding: 20px;
    background-color:white;
    margin: 5px;
 /*  box-shadow:0px 0px 15px -10px rgb(0,0,0.75);
    -webkit-box-shadow:0px 0px 15px -10px rgb(0,0,0.75);
     */
}
/* .client-store-showtop{
    background-image:linear-gradient(to bottom right, var(--app-primary), snow);
        padding:3px 5px;
    color:snow

} */
.client-storeUpdate{
    flex:2;
    padding: 20px;
   /*  box-shadow:0px 0px 15px -10px rgb(0,0,0.75);
    -webkit-box-shadow:0px 0px 15px -10px rgb(0,0,0.75); */
    margin-left: 20px;
}
.storeShowTop{
    display: flex;
    align-items: center;
}
.storeShowImg{
    width: 60px;
    height:60px;
    border: 2px solid var(--app-primary);
    border-radius: 50%;
    object-fit: cover;
}
.storeShowTopTitle{
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}
.storeShowstorename{
    font-weight: 600;
}
.storeShowstoreTitle{
    font-weight: 300;
}
.storeShowTitle{
    font-size: 14px;
    font-weight: 600;
    color: rgb(175, 170, 170);
    text-align: left;

}
.storeShowBottom{
    margin-top: 20px;
}
.storeShowInfo{
    display: flex;
    align-items: center;
    margin: 20px 0px;
    color: #444;
}
.storeShowIcon{
    font-size: 16px !important;
}
.storeShowInfoTitle{
    margin-left: 10px;
}
.categoryItemTitle{
    padding: 3px 5px;
    margin-bottom: 1px;
    font-weight: 500;
    color:#444;
    border-left: 1px solid gray;
}
.client-storeUpdateForm{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    padding: 5px;
    background-color:white;
    ;
}
.storeUpdateImg{
    width: 100px;
    height: 100px;
    border-radius: 10px;
    margin-right: 10px;
}
.storeUpdateTitle{
  font-size: 24px;
  font-weight: 600;
}
.storeUpdateItem{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.storeUpdateSelectItem{
    display: flex;
    flex-direction: column;
    width: 80%;
}
.storeUpdateSelectItem>label{
    margin-bottom: 5px;
    font-size: 14px;
    text-align: left;

}

.storeUpdateItem>label{
    margin-bottom: 5px;
    font-size: 14px;  
     text-align: left;

}
.storeUpdateInput{
    border: none;
    width: 80%;
    height: 30px;
    border-bottom: 1px solid gray;
}

.storeUpdateTextarea{
    border: none;
    width: 80%;
    height: 80px;
    border-bottom: 1px solid gray;
}
.storeUpdateRight{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.storeUpdateLeft{
    border: 0px solid;
    width: 100%;
}
.storeUpdateItemCheckBox{
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0px solid gray;
    width: 80%;
}
.storeUpdateUpload{
    display: flex;
    align-items: center;
}
.storeUpdateIcon{
    cursor: pointer;
}
.updateactionContainer{
    margin-top: 10PX;
    height:100%;
    border:0px solid
}
.storeUpDateButton{
    border-radius: 5px;
    border: none;
    padding:5px;
    cursor: pointer;
    background-color: var(--dashboard-primary);
    color: white;
    font-weight: 600;
    width: 100%;
}
@media only  screen and (max-width:620px){
    .mystore{
        height: 100vh;
        width: 100%;
        padding: 0px;
        overflow: scroll;
    }
    .storeTitleContainer {
      
        padding: 1px; 
    }
    .updateactionContainer{
        height:auto;
        border:0px solid
    }
    .client-storeShow{
        flex: 1;
        padding: 10px;
    }
    .client-storeUpdate{
        flex:2;
        padding: 8px;
       /*  box-shadow:0px 0px 15px -10px rgb(0,0,0.75);
        -webkit-box-shadow:0px 0px 15px -10px rgb(0,0,0.75); */
        margin-left: 0px;
    }
    
    .storeUpdateItemCheckBox{
        width: 100%;
    }
    .storeUpdateTextarea ,.storeUpdateInput{
        width: 100%;
    }
    .storeUpdateSelectItem{
        width: 100%;
    }
}