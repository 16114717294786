.client-widraw{
    flex: 4;
    padding: 0px;
    margin-bottom: 50px;
    width: 100%;
    height: 100vh;
    border:1px solid;
    margin-right: 10px
   /*  background-color: darkslategrey; */
}
.pageTitleContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 5px;
    
 }
    
    .pageTitle{
        border: none;
        padding: 5px 10px;
        height: 34px;
        border-radius: 5px;
        font-weight: 600;
    }
    .pageTitleButton{
        border: none;
        padding: 5px 10px;
        background-color: --dashboard-primary;
        color: white;
        cursor: pointer;
        border-radius: 5px;
        font-size: 16px;
    }
    
  .circularProgress{
    position:fixed;
    margin: 25% 40%;
  }
  @media only  screen and (max-width:620px){
    .widraw{
        padding: 0px;
        margin: 0px;

    }
   
   
  .pageTitle{
    font-size: 16px;
  }
  .client-withdraw-Button{
    text-transform: none !important;
    display: 'inline-block';
    padding: 5px 25px !important;
    min-height: 0;
    min-width: 0;
    font-size: 0.8rem !important;
  
  }

  .client-withdraw-max-Button{
    text-transform: none !important;
    display: 'inline-block';
    padding:  2px !important;
    min-height: 0;
    min-width: 0;
    font-size: 0.7rem !important;
  
  }
}