.product-review{
    flex:4;
    padding: 20px;
}
.pm-productTitleContainer{
    width:  100%;
    padding: 2px 5px ;
  
    
}


.productInfoImg{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
}

.productEdit{
    padding: 20px;
    margin: 20px;
    box-shadow:0px 0px 15px -10px rgb(0,0,0.75);
-webkit-box-shadow:0px 0px 15px -10px rgb(0,0,0.75);
 
}

.formItem > select{
    margin-bottom: 10px;
    width: 100%;
   
}







@media only  screen and (max-width:620px){
    .product{
        padding: 0px;
    }
    .storeCurrencyLabel {
        width: 98%;
        padding: 1%;
    }
    .productTitleContainer{
        padding: 5px;
        width: 100%;
    }
    .actions{
        border: 0px solid;
        width:40%;


    }
}