:root{
  --app-primary:  #6d1b7b/*#c647caea #ff9800 */;
  --app-secondary:#ddbbdd;
  --app-secondary-light:#f3e6f9;
  --app-font-info:rgb(244, 186, 159);
  --app-widget-background:white,
  --app-font-darkgray:darkgrey;
  --app-font-price:red;
  --app-font-background:rgba(140, 103, 103, 0.187);
  --order-status-paid:#2a7ade;
  --order-status-shipped:#d39012;
  --order-status-recieved:#8fd312;
  --order-status-complete:#34af4d;
  --order-status-cancelled:#dc3972;
  --order-status-declined:#dc3972;
}

.App {
  background-color:white;
  height: auto;
  width: 100%;
  
  border: 0px solid ;
  overflow: scroll;
 
}
.boxMain{
  width: 100%;
  background-color: transparent;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.circularProgress-wrapper{
  height: 50px;
  width: 50px;
  padding: 9px;
  z-index:1000 ;
  position:fixed;
/**/   margin: 45vh 45vw; 
  background-color: rgba(181, 181, 181, 0.333);
}

.preloadProgress-wrapper{
  height: 50px;
  width: 50px;
  padding: 9px;
  z-index:1000 ;
  position:fixed;
/**/   margin: 45vh 45vw; 
}
.recents-products-title{
  color:var(--app-font-darkgray) ;
    margin:0vw 16vw;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.client-Order-Status{
  text-transform: none !important;
  display: 'inline-block';
  padding: 2px 3px !important;
  min-height: 0;
  min-width: 0;
  font-size: 0.7rem !important;

}
.client-action-Button{
  text-transform: none !important;
  display: 'inline-block';
  /*padding: 4px 8px  !important;
  min-height: 0;
  min-width: 0;
  font-size: 0.7rem !important;*/
  z-index:2

}




.clent-product-life{
  text-transform: none !important;
  display: 'inline-block';
/*   padding: 2px 4px !important; */
  min-height: 0;
  min-width: 0;
  font-size: 0.7rem !important;
 /*  z-index: 999999 !important; */

}

.client-cart-qty-btn{
  text-transform: none !important;
  display: 'inline-block';
  padding: 0px !important;
  width:3vw !important;
  height: 20px !important;
  min-width: 10% !important;
  font-size: 1.1rem !important;

}
.client-cart-specs-btn{
  text-transform: none !important;
  display: 'inline-block';
   padding:2px 4px !important; /**/
  min-height: 0;
  min-width: 10% !important;
  font-size: 0.6rem !important;

}

.client-buynow-Button{
  text-transform: none !important;
  display: 'inline-block';
  min-height: 0;
  width: 100% !important;
  min-width: 0;
  font-size: 0.9rem !important;
  border-radius: 30px !important;

}


.App-link {
  color: #61dafb;
}
.link{
  text-decoration: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-device-width:820px) {
  .boxMain{
      border: 0px solid;
      margin-bottom: 0vh;
      
  }
   .recents-products-title{
      margin:0% 5%
  
  }
  .client-buynow-Button{
   
    width: 70vw !important;
    min-width: 50vw !important;
    font-size: 1rem !important;
  
  }
  .client-cart-qty-btn{
    padding: 0px !important;
    width:8vw !important;
    min-height: 0;
    min-width: 10% !important;
    font-size: 1.1rem !important;
  
  }
  
}
@media only screen and (max-width:620px) {
 :root{
 padding-top: -10 !important;
 };
 .App{
  height:fit-content;
  margin-bottom: 0vh;
  overflow: unset;
 }
 .client-cart-qty-btn{
  width:8vw !important;
 
}
.client-action-Button{
  text-transform: none !important;
 /* display: 'inline-block';*/
  padding: 2px 4px  !important;
  min-height: 0;
  min-width: 0;
  font-size: 0.7rem !important;
  z-index:2

}
.productLife{
  text-transform: none !important;
  padding: 2px 4px  !important;
  min-height: 0;
  min-width: 0;
  font-size: 0.7rem !important;
  z-index:2

}
  .client-buynow-Button{
   
    width: 55vw !important;
    min-width: 35vw !important;
    font-size: 0.8rem !important;
    border-radius: 30px !important;
  
  }
  
}
