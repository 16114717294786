.client-edit-productImg{
    height: 12vh;
    width: 100%;
  object-fit: cover;
  border: 1px solid rgb(151, 150, 150);
  margin-right: 2px;
    
} 
.productImages{
   display: flex;
   justify-content:space-between;
   width: 100%;
   border: 0px solid;
   margin-top: 10px;
 /**  box-shadow:0px 0px 15px -10px rgb(0,0,0.75); ;
   -webkit-box-shadow:0px 0px 15px -10px rgb(0,0,0.75);*/
 
  }
  .imageGallery{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0px solid;
    width: 100%;
  }

  .imageswrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.image-gallery-circularProgress-wrapper {
       /* height: 50px; */
    /* width: 50px; */
    /* padding: 9px; */
    display: none;
    z-index: 1000;
    position: absolute;
    margin: 9% 10%;
    /* background-color: rgba(181, 181, 181, 0.333); */
}
