.product {
    flex:4;
    padding: 20px;
    height:100vh;
    overflow: scroll;
    background-color: white;
}
.pm-productTitleContainer{
    width:  100%;
    padding: 2px 5px ;
}
.actions {

    padding: 2px;
    width:65%;display:flex;
    justify-content:space-between;
    align-self: end;
}
.productAddButon{
    width: 80px;
    border: none;
    padding: 5px 10px;
    background-color: var(--dashboard-primary);
    color: white;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
}
.productTop{
    display: flex;
}
.pm-productBottom{
    margin: 0px 16px;
    padding: 0px 5px;
    border: 0px solid darkgray;
}

.productTopRight{
    padding: 20px;
    margin: 20px;
    box-shadow:0px 0px 15px -10px rgb(0,0,0.75);
-webkit-box-shadow:0px 0px 15px -10px rgb(0,0,0.75);
   
}
.pm-productInfoTopWrapper{
    padding: 10px;
    margin: 5px;
  /*   box-shadow:0px 0px 15px -10px rgb(0,0,0.75);
-webkit-box-shadow:0px 0px 15px -10px rgb(0,0,0.75); */
   height: auto;
}

.productInfoImg{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
}
.pm-productInfoTop{
    display: flex;
    justify-content: flex-start;
    align-items: center;

}
.productName{
    font-size: 14px;
    font-weight: 600;
}
.productNameinput{
    font-size: 18px !important;
    padding: 4px 5px !important;
    font-weight: bold;
}
.pm-productInfoBottom{
    margin-top: 10px;
    width: 100%;
}
.pm-productInfoItem{
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: 0px solid;
}
.productInfoValue{
    font-weight: 300;
}
.productInfoInput{
    height: 20px;
    width: 80px;
    border: none;
    border-bottom: 1px solid;
}
.productEdit{
    padding: 20px;
    margin: 20px;
    box-shadow:0px 0px 15px -10px rgb(0,0,0.75);
-webkit-box-shadow:0px 0px 15px -10px rgb(0,0,0.75);
 
}
.productForm{
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.formItem{
    display: flex;
    flex-direction: column;
}
.formItem > input{
    margin-bottom: 10px;
    width: 100%;
    border: none;
    padding: 5px;
    border-bottom: 1px solid grey;
}
.formItem > select{
    margin-bottom: 10px;
    width: 100%;
   
}
.formItem > label{
    margin-top: 10px;
    margin-bottom: 10px;
    color: darkgray;
    text-align: left;
   
}
.productFormLeft > label{
    margin-top: 5px;
    margin-bottom: 10px;
    color: grey;
}
.productFormLeft {
    display: flex;
    flex-direction: column;
    width: 130%;
    border: 0px solid;

}


.productFormLeft > select{
    margin-bottom: 10px;
    width: 100%;
  
}

.productFormMiddle{
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    align-items:center;
    width: 90%;
    border: 0px solid;

}
.productFormMiddle > label{
    margin-top: 5px;
    margin-bottom: 10px;
    color: grey;
}
.productmeasurementWrapper{
    display:grid;
    grid-template-columns: auto auto auto auto;
    border:0px solid darkgray;
    width:100%;
    height: auto;
}
.measurementItem{
    width:100%;
    height: 4vh;
    font-size: 12px;
    margin: 5px 10px;
    text-align:center;
}
.actions{
    margin: 5px;
    display:flex;
    flex-direction:row
}
.productUploadImg{
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
    margin-right: 20px;
}


.productFormRight{
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 300px;
    justify-content: space-around;
    border: 0px solid;
    padding: 5px 20px;
}
.productUpload{
    display: flex;
    align-items: center;
   
}
.editButtonContainer{
    margin: 5vh 5px;
    width: 100%;
}
.productButton{
    width: 80%;
    border: none;
    padding: 5px;
    background-color: var(--dashboard-primary);;
    border-radius: 5px;
    color: white;
    font-weight: 600;
    cursor: pointer;
}
.storeCurrencyLabel{
    width:30%;
    display:flex;
    justify-content:space-between;
}
@media only  screen and (max-width:620px){
    .product{
        padding: 0px;
    }
    .storeCurrencyLabel {
        width: 98%;
        padding: 1%;
    }
    .productTitleContainer{
        padding: 5px;
        width: 100%;
    }
    .actions{
        border: 0px solid;
        width:40%;


    }
}