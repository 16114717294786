
.detailContainer{
    display: flex;
    justify-content: space-between;
    padding: 5px 7px;
    margin-top: 5px;
    align-items: center;
    border: 1px solid;
    height: 200px;
    
}
.media{
    height: 80%;
    width:28%
}
.tranxdetailsContainer{
    display:flex;
    flex-direction: column;
    font-size:14px;
    border:0px solid red;
    height: 100%;
    width: 38%;
}

.tranxdetailsItem{
    display: flex;
    align-items: center;
    margin: 4px 0px;
    font-size: 14px;
    color: #444;
}
.tranxdetailsItemTitle{
    font-size: 12px;
    font-weight: 500;
    margin-left: 10px;
}
.infoRight{
 display: flex;
 flex-direction: column;
 justify-content: space-around;
 height: 100%;
 border: 0px solid;
}
.measurementWrapper{
  
    padding: 2px 3px;
    display: flex;
    flex-direction: column;
}
.grid-container-wrapper{
    border: 1px solid;
    border-radius: 4px;
    padding: 4px;
}
.measurement-grid-itemWrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items:center;
    font-size: 10px;
}
.userShowIcon{
    font-size: 16px !important;
}
