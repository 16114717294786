.cat-group-form{
  padding: 10px 15px;
}
.group-list{
  border: 1px solid darkgray;
  height: 60vh;
  overflow: scroll;
  padding:10px
}
ul{
    list-style-type:none;
}
.group-list ul li{
    cursor: pointer;
    text-decoration: none;
    border: 1px solid darkgray;
    border-radius: 4px;
    margin:2px 2px;
    padding:1px 3px;
    display:flex;
    justify-content:space-between;
    padding:0px  10px 0px 10px
} 

.group-list ul li .li-title{
  text-align: left;
  padding: 2px;
  cursor: pointer;
  border:0px solid;
  width: 90%;
}
.group-list ul li .remove{
    cursor: pointer;
    font-size: 1rem;
    color:var(--app-primary)
} 
.group-list ul li img{
  width:20px;
  height:20px;
  margin-top: 2px;
  margin-right: 2px;
} 

.category-item{
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;

}
.category-item>label{
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  color: rgb(151, 150, 150);
}
.categroy-item>input{
  width: 100%; 
  height: 6vh;
  border: 1px solid gray;
  /* border-bottom: 1px solid gray; */
  padding: 3px 5px;
  font-size: 14px;
}
