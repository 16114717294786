.client-title-bar{
    background-color:white;
    padding: 3px 5px;
   /*  margin:2px; */
}
.client-titleButton{
    text-transform: none !important;
    display: 'inline-block';
    font-size:0.6rem !important;
    padding:2px 4px !important;
    min-height: 0;
    min-width: 0;

}