.root{
   /*  border-radius:5px ;
    border: 1px solid orange;
     padding:40px 20%; */
     height:100vh;
    /*  width:30vw; */
     overflow: scroll;
   /*  box-shadow:0px 0px 15px -10px rgb(0,0,0);
    -webkit-box-shadow:0px 0px 15px -10px rgb(0,0,0); */
}
@media only screen and (max-width:620px) {
    .root{
        padding:2px 3px;
/*         width:100vw;
 */    };
    .para-text{
        font-size: 0.6rem !important;
    }
    .header-text{
    font-size: 0.8rem !important;
    }
   
}