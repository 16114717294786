
.floating-component{
    z-index: 999999999;
    position:fixed;
    top:6%;
    bottom: 20px; /* Adjust the distance from the bottom as needed */
    right: 24vw; /* Adjust the distance from the right as needed */
    height: 40vh;
/*  background-color: whitesmoke;
 */    color: inherit;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    display: none;
  }
  
  .floating-component.visible {
    display: block;
  }
  
@media only screen and (max-width:620px) {
  .floating-component {
    position:fixed;
    top:4%;
    bottom: 20px; /* Adjust the distance from the bottom as needed */
    right: 20px; /* Adjust the distance from the right as needed */
    height: 40vh;
  }
}