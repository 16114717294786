#category {
    width: 15vw;
    height: 100vh;
    background-color:white /* rgb(251, 251, 255) */;
   position: fixed;
    top:  40;
    left: 0;
    transition: transform .1s
                cubic-bezier(0, .52, 0, 1);
    overflow: scroll;
    z-index: 1000;
  }
   
  #category.hide {
    transform: translate3d(-100vw, 0, 0);
  }
   
  #category.show {
    flex: 1;
    transform: translate3d(0vw, 0, 0);
    overflow: hidden;
  }
  @media only  screen and (max-width:620px){
    #category {
      width: 40vw;
      top:0px
    }
   
}

/* <div style="background: linear-gradient(45deg, black 25%, yellow 25%, yellow 50%, black 50%, black 75%, yellow 75%, yellow 100%) 0% 0% / 32px 32px; height: 5px; width: 100%;"></div> */
.shadow{
    box-shadow:0px 0px 15px -10px var(--app-primary);
-webkit-box-shadow:0px 0px 15px -10px var(--app-primary);
}