.specs{
    /* display: flex;
    justify-content: space-between;
    border: 0px solid;
    margin-top: 20px;
    background: snow; */
    padding: 1px;

}
.client-specsformItem{
   /*  display: flex;
    flex-direction: column;
    width: 48%; */
    border:1px solid darkgray;
    padding:10px 4px;
    
}
/* .specsformItem > input{
    margin-bottom: 10px;
    width: 90%;
    border: none;
    padding: 5px;
    border-bottom: 1px solid grey;
}
.specsformItem > select{
    margin-bottom: 10px;
    width: 90%;
   
} */


.specsmeasurementWrapper{
    display:grid;
    grid-template-columns: auto auto auto auto;
    border:0px solid darkgray;
    width:100%;
    height: auto;
}
.specsmeasurementItem{
    width:90%;
    height: 4vh;
    font-size: 12px;
    margin: 5px 5px;
    text-align:center;
}
.actions{
    margin: 2px;
    display:flex;
    flex-direction:row;
    border:0px solid;
    padding: 0px;
}