body {
  margin: 0;
  align-self: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Mulish'
    ,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
#root{
  width:100%;
  
  
}

/* code {
  font-family: Consolas,source-code-pro, Menlo, Monaco, 'Courier New',
    monospace;
} */
