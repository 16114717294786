.client-newProduct{
   background-color: white;
   padding: 0;
   height:100vh;
   overflow: scroll;
}
.addProductTitleContainer{
display: flex;
align-items: center;
justify-content: space-between;
padding: 10px 5px;

}

.client-addproductStoreTitle{
    border: none;
    /* background-color:rgb(228, 228, 250); */
    padding: 5px 10px;
    height: 34px;
    border-radius: 5px;
    font-weight: 600;
}
.client-addProductTitle{
    margin: 5px 5px;
}
.productFormTop{
    display: flex;
    align-items:center;
    justify-content: space-between;       
    margin-bottom: 60px;
    border: 0px solid;
}
.productFormTopItem{
    border: 0px solid;
    width: 26vw;
}
.addProductFormContainer{
    background-color: white;
    border: 0px solid;
    padding: 10px;
    width: 100%;
}
.addProductForm{
    margin-top: 10px;
}

.client-addProductItem{
    padding: 1px;
    display: flex;
    flex-direction: column;
    border: 0px solid;
}
.client-addProductItem>input{
    border: 0px;
    width: 100%;
    font-size: 0.5rem;
    border-bottom:1px solid rgb(151, 150, 150);
}
.client-addProductItem>select{
    padding: 5px;
    border: 0px;
    width:100%;
    border-bottom:1px solid rgb(151, 150, 150);
}
.client-addProductItem>textarea{
    margin:8px  0px 6px 0px;
    border: 0px;
    width:100%;
    border-bottom:1px solid rgb(151, 150, 150);
}
.client-addProductItem>label{
  margin-bottom: 5px;
  font-size: 1rem;
  text-align:left;
}
.addProductButton{
    margin: 20px 0px;
    padding: 7px 10px;
    width:100%;
    border: none;
    border-radius: 10px;
    background-color: var(--dashboard-primary);
    color: white;
    cursor: pointer;
}
.ProductListButton{
    border: none;
    padding: 5px 10px;
    background-color: var(--dashboard-primary);
    color: white;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
}
.description{
    margin-top:5vh ;
}
@media only  screen and (max-width:620px){
    .newProduct{
        width: 97%;
        padding: 0px;
    }
    .addProductTitle{
        font-size: 16px;
    }
}