.pm-productsList{
    flex:4;
    padding: 0px;
    height: 100vh;
    border:0px solid;
    overflow: scroll;
    margin-right: 0px;
    background-color:white
}
.productsTitle{
    border: none;
    background-color:rgb(228, 228, 250);
    padding: 5px 10px;
    height: 34px;
    border-radius: 5px;
    font-weight: 600;
}
.AddProductButton{
    border: none;
    padding:5px 10px ;
    background-color: var(--dashboard-primary);
    color: white;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
}
.productListItem{
    display: flex;
    align-items: center;

}
.productsTitleContainer{
    width: 98%;
    display: flex;
    align-items: center;
    justify-content:space-between;
    padding: 10px 5px;
    }
.productListImg{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}
.productlistEdit{
    border:none;
    border-radius: 10px;
    padding: 5px 10px; 
    background-color: #3bb077;
    color: white;
    cursor: pointer;
    margin-right: 10px;

}
.productlistDelete{
    color: red;
    cursor: pointer;
}
.productlistEditIcon{
    color: #555;
    cursor: pointer;
}

@media only  screen and (max-width:620px){

    .pm-productsList{
        width: 100%;
        padding: 0px;
        overflow: hidden;
    margin-right: 0px
    }
    .productsTitleContainer {
       
        padding: 5px 1px;
    }
    .pm-product-card{
        margin:2px 2px;
        padding:3px 3px
    }
    .pm-product-title{
        margin:1px 2px;
        padding:2px 3px
    }
    .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-root .MuiDataGrid-cell {
        padding: 1px !important;
        
    }
    .MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
       
         padding: 0px !important;
        
  
    }
    .MuiDataGrid-menuIcon {
      display: none !important;
      
  }
  
}