.login{
    display: grid;
    grid-template-columns: 35% auto 35%;
    margin-top: 20px;
}
.logInMiddle,.logInLeft,.logInRight{
   
flex:1
}
.logInMiddle{
    display: flex;
    justify-content:column;
    padding: 20px;
}
.userUpdateImg{
    width: 100px;
    height: 100px;
    border-radius: 10px;
    margin-right: 10px;
}
.logInTitle{
    display: flex;
    align-items: center;
  font-size: 24px;
  font-weight: 600;
}
.loginFormWrapper{  
  padding: 100px 20px;
  width: 100%; 
  margin-bottom: 40px;
    border: none;
     box-shadow:0px 0px 15px -10px rgb(0,0,0.75);
    -webkit-box-shadow:0px 0px 15px -10px rgb(0,0,0.75);
}
.loginForm{
   border: none;
}
.loginItem{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    
}

.loginItem>label{
    margin-bottom: 5px;
    font-size: 14px;
}
.loginInput{
    border: none;
    width:  100%;
    height: 30px;
    border-bottom: 1px solid gray;
}
.userUpdateRight{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.userUpdateUpload{
    display: flex;
    align-items: center;
}
.userUpdateIcon{
    cursor: pointer;
}.signUpLoginItem{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    padding: 10px 20px;
}
.btnLogIn ,.btnSignUp{
    border-radius: 5px;
    border: none;
    margin-top: 20px;
    padding:5px;
    width: 80px;
    cursor: pointer;
    color: white;
    font-weight: 600;
}
.btnLogIn{
    background-color: darkblue;
}
.btnSignUp{
    background-color: rgb(109, 240, 183);

}
@media only  screen and (max-width:620px){
    .login{
      grid-template-columns: auto;
    }
}