.Completed{
  background-color: #e5faf2;
  color: #34af4d;
  padding: 2px 2px;
  text-align: center;
  font-size: 13px !important;
  border:1px solid #34af4d !important;
}


.Shipped{
    background-color: #faf3e5;
    color:#d39012;
    padding: 5px 4px;
    text-align: center;
    width: 100% !important;
    border:1px solid #d39012 !important;
  }
.Approved{
    background-color: #e5faf2;
    color:#3bb077 ;
    padding: 5px 4px;
    text-align: center;
    width: 100% !important;
    border:1px solid #3bb077 !important;
  }
  .Recieved{
    background-color: #e4f6c3;
    color:#8fd312;
    padding: 5px 4px;
    text-align: center;
    width: 100% !important;
    border:1px solid #8fd312 !important;

  }
 /*  .Processing ,.Recieved, .Reviewed , .Evaluated{
    background-color: #fff0f1;
    color:#d6c32fc0 ;
    padding: 5px 4px;
    text-align: center;
    width: 100% !important;
    border:1px solid #d6c32fc0 !important;

  } */
  .Declined{
    background-color: #fff0f1;
    color:#dc3972 ;
    padding: 5px 4px;
    text-align: center;
    width: 100% !important;
    border:1px solid #dc3972 !important;

  }
  .Cancelled{
    background-color: #fff0f1;
    color:#f50057 ;
    padding: 5px 4px;
    text-align: center;
    width: 100% !important;
    border:1px solid #f50057 !important;

  }
.Paid{
    background-color: #ebf1fe;
    color:#2a7ade ;
    padding: 5px 4px;
    text-align: center;
    width: 100% !important;
    border:1px solid #2a7ade !important;

  }
  .orderNumber{
    padding: 5px 4px;

  }
@media only  screen  and (max-width:620px){
 .Completed , .Approved, .Paid, .Cancelled , .Declined , .Evaluated , .Processing , .Recieved , .Reviewed , .Shipped{
    border-radius: 2px;
    font-size:11px !important
  
}
}