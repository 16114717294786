.category{
    background-color:snow;
     border:0px solid;
    width:99%;
    padding:5px 200px
}
.categoryItem{
width: 100%;
border-radius: 10px;
padding: 30px;
cursor: pointer;
box-shadow:0px 0px 15px -10px rgb(0,0,0);
-webkit-box-shadow:0px 0px 15px -10px rgb(0,0,0);
 }
.categoryItem{
    font-style: 20px;
}
.categoryItemContent{
    margin: 10px 0px;
    display: flex;
    align-items: center;
}
.categoryProducts{
    font-size: 30px;
    font-weight: 600;
}
.categoryProductsRate{
     display: flex;
     align-items: center;
     margin-left: 20px;
}
.categoryIcon{
    font-style: 14px;
    margin-left: 5px;
    color: green;
}
.categoryIcon.negative{
 
color: red;
}

.categorySub{
    font-style: 15px;
    color: grey;
}
@media only screen and (max-width:420px) {
    .category{
        width:99%;
        padding:0px 0px
    }
}
