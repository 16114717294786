.contactContainer{
    width: 100%;

}
.contact{
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.contact-item{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;

}
.contact-item>label{
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    color: rgb(151, 150, 150);
}
.contact-item>input{
    border: 1px solid gray;
    width: 60%;
    height: 50px;
   /*  border-bottom: 1px solid gray; */
    padding: 3px 5px;
    font-size: 14px;
}